import React from "react"

import {
  Box,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Input,
} from "@chakra-ui/react"

const SubscribeModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Box mt="6" fontSize={30} fontWeight={600} color="#3952D3">
              Let's Make something Grape
            </Box>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mx={{ lg: "16", base: "2" }}>
            <Box fontSize={16} fontWeight={600} textAlign={"center"}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim
            </Box>
            <FormControl isRequired>
              <Flex justifyContent={"center"} mt="10">
                <Input
                  id="your-email"
                  placeholder="Your email*"
                  rounded={"full"}
                  textAlign={"center"}
                  fontSize={15}
                  mb="2"
                  w={{ lg: "70%", base: "100%" }}
                />
              </Flex>

              <Flex justifyContent={"center"}>
                <Box
                  as="button"
                  rounded={"full"}
                  textAlign={"center"}
                  fontSize={15}
                  mb="14"
                  w={{ lg: "70%", base: "100%" }}
                  bgGradient="linear(to-l, #B102FE, #0670C3)"
                  color="white"
                  fontWeight={400}
                  py="2"
                >
                  Subscribe
                </Box>
              </Flex>
            </FormControl>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SubscribeModal
